import { graphql } from "gatsby"
import React from "react"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import CtaToPageBlock from "../../molecules/tileable-blocks/CtaToPageBlock"
import { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"

const DatoCtaToPage = (props: Queries.DatoCtaToPageFragment) => {
  // console.debug("Rendering DatoCtaToPage", props)
  const { textBackground, layout, text, title, linkText, image, route, urlParams, ...rest } = props

  if (!route || !linkText || !title) {
    return null
  }

  return (
    <CtaToPageBlock
      {...rest}
      layout={(layout || "Tile") as MediaTextBlockLayout}
      backgroundColor={(textBackground || "BrandWhite") as HarviaBackgroundColor}
      text={text || ""}
      title={title}
      linkText={linkText}
      image={image || undefined}
      route={route}
      urlParams={urlParams}
    />
  )
}

export default DatoCtaToPage

export const query = graphql`
  fragment DatoCtaToPage on DatoCmsCtaToPage {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    linkText
    image {
      ...DatoImageDefault
    }
    route {
      originalId
    }
    urlParams
    textBackground
    layout
  }
`
